<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 9.573 18.427"
    aria-hidden="true"
  >
    <path
      id="Path_161"
      data-name="Path 161"
      d="M14.451,5.059h1.732V2.129A24.057,24.057,0,0,0,13.658,2a3.951,3.951,0,0,0-4.22,4.33V8.744H6.61v3.28H9.439v8.4h3.391v-8.4h2.819l.424-3.28H12.829V6.653C12.829,5.685,13.087,5.059,14.451,5.059Z"
      transform="translate(-6.61 -2)"
      fill="currentColor"
      class="transition-colors duration-150"
    />
  </svg>
</template>
